import {
  BORDER_RADIUS,
  COLORS,
  DEFAULT_TRANSITION_ALL,
  DEFAULT_BOX_SHADOW,
} from "utils/styles";

import styled from "styled-components";

export default styled.article`
  ${DEFAULT_TRANSITION_ALL};
  ${BORDER_RADIUS};
  background: ${COLORS.WHITE};
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;

  &:hover {
  }
`;
