import styled, { css } from "styled-components";
import { BREAKPOINT } from "utils/styles";

export const CarouselCaptions = styled.div``;
export const CarouselCaption = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;
`;
export const CarouselCaptionTitle = styled.span.attrs({
  className: "mabry-medium",
})`
  font-size: 1.5rem;
  display: block;
  @media ${BREAKPOINT.M} {
    font-size: 1.125rem;
    text-align: left;
    align-self: flex-start;
    margin-bottom: 0.5rem;
  }
`;
export const CarouselCaptionContent = styled.span`
  display: block;
  font-size: 1.125rem;

  a {
    color: black;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }

  @media ${BREAKPOINT.M} {
    font-size: 0.9rem;
  }
`;

export const CarouselContainer = styled.div`
  width: min(100vw - 2rem, 900px);
  margin: auto;
`;
